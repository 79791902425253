body {
    /* Fixed Body for fixed navigation bar at top */
    position: fixed;
    height: 100%;
    width: 100%;
    margin: 0;
    padding: 0;
    overflow-y: hidden;

    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    /* Disable Text Selection */
    -webkit-user-select: none;
    -moz-user-select: -moz-none;
    -ms-user-select: none;
    user-select: none;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

Link, a {
    text-decoration: none
}

.content {
    /* Content of app besides Navigation Bar */
    width: 100%;
    height: 100%;
    overflow-y: scroll;
}

.footer {
    z-index: 1;
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
}
